h1 {
  color: #513388;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 3vh;
  text-align: center;
}
.h1 {
  color: #513388;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 3vh;
  text-align: center;
}
h2, h3 {
  color: #444;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  text-align: center;
}
body {
  -webkit-user-select: none;
          user-select: none;
  margin: 0;
  text-align: center;
}
body, input[type="text"], button {
  color: #333;
  background-color: #fff5fa;
  font-family: Cambria, Georgia;
}
div {
  margin: 20px;
}
/* everywhere else */
* {
  font-family: Helvetica, sans-serif;
}
.debug {
  color: tomato !important;
  border: tomato 1px solid;
  margin-top: 50px;
}
footer {
  position: fixed;
  bottom: 0;
}
.myButton {
  margin: 10px 5px 10px 5px;
  box-shadow: inset 0px 0px 0px 0px #ffffff;
  background: linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #dcdcdc;
  display: inline-block;
  cursor: pointer;
  color: #666666;
  font-family: Texturina;
  font-size: 2.6vh;
  font-weight: bold;
  padding: 15px 20px;
  text-decoration: none;
  text-shadow: 0px 0px 0px #ffffff;
}
.myButton:hover {
  background: linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
  background-color: #f6f6f6;
}
.myButton:active {
  position: relative;
  top: 1px;
}
.q {
  font-family: 'Major Mono Display';
  font-size: 5vh;
  margin-bottom: 5vh;
}
.pname {
  font-family: 'Dancing Script';
  font-size: 300%;
}
.qimg {
  height: 40vh;
}
.body {
  margin-right: 0;
  overflow: hidden !important;
  background: url('sakura_tree.png') no-repeat;
  background-position: right;
  background-size: 40%;
  padding-bottom: 150px;
}
.scoreboard {
  -webkit-user-select: text;
          user-select: text;
}
.css-input {
  padding: 5px;
  font-size: 16px;
  border-width: 1px;
  border-color: #CCCCCC;
  background-color: #FFFFFF;
  color: #000000;
  border-style: solid;
  border-radius: 0px;
  box-shadow: 0px 0px 5px rgba(66, 66, 66, .75);
  text-shadow: 0px 0px 5px rgba(66, 66, 66, .75);
}
.css-input:focus {
  outline: none;
}
.name-input {
  padding: 18px;
  font-size: 300%;
  border-width: 1px;
  border-color: #CCCCCC;
  background-color: #FFFFFF;
  color: #000000;
  border-style: solid;
  border-radius: 0px;
  box-shadow: 0px 0px 5px rgba(66, 66, 66, .75);
  text-shadow: 0px 0px 5px rgba(66, 66, 66, .75);
  font-family: 'Dancing Script';
  text-align: center
}
.name-input:focus {
  outline: none;
}
iframe {
  height: 40vh;
  width: 70vh;
}
/*body {*/
/*  !*background-color: #2b2c3a;*!*/
/*  padding: 20px;*/
/*}*/
/*#blossom_container {*/
/*  background-color: #f1f1f1;*/
/*  position: absolute;*/
/*  width: calc(100% - 40px);*/
/*  height: calc(100% - 40px);*/
/*}*/
.petal {
  background: url("/assets/img/cherry-blossom.png") no-repeat;
}
.petal.petal-style1 {
  width: 45px;
  height: 20px;
  background-position: -31px 0;
}
.petal.petal-style2 {
  width: 42px;
  height: 22px;
  background-position: 0 -23px;
}
.petal.petal-style3 {
  width: 37px;
  height: 24px;
  background-position: 0 -50px;
}
.petal.petal-style4 {
  width: 26px;
  height: 34px;
  background-position: -49px -35px;
}
